@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/MyriadPro-Regular.otf'),
  url('../../fonts/MyriadPro-Regular.woff');
}


@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/MyriadPro-Bold.otf'),
  url('../../fonts/MyriadPro-Bold.woff');
}

@font-face {
  font-family: 'BloggerSans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Blogger_Sans.otf'),
  url('../../fonts/Blogger_Sans.woff');
}

@font-face {
  font-family: 'MyriadPro';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/Blogger_Sans-Italic.otf'),
  url('../../fonts/Blogger_Sans-Italic.woff');
}

.site {


  h2 {
    font-family: 'BloggerSans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 2.375rem !important;
    text-transform: initial !important;
  }

  p {
    font-family: 'MyriadPro', sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem;
  }

  .red-diamond-list ul,
  .red-diamond-list li {
    font-family: 'MyriadPro', sans-serif !important;
  }

  a {
    &:focus {

      .cooperation-category,
      .training-category {
        background-color: #006ba6 !important;
      }
    }

    &:hover {

      .cooperation-category,
      .training-category {
        background-color: #006ba6 !important;
      }
    }
  }

  .text-mb-28 {
    font-family: 'BloggerSans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 2.25rem !important;
  }

  .text-cl-16 {
    font-family: 'MyriadPro', sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem;
  }

  .text-cl-20 {
    font-family: 'MyriadPro', sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.25rem;
  }

  .text-bs-22 {
    font-family: 'BloggerSans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.375rem !important;
  }


  .text-cr-38 {
    font-family: 'BloggerSans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 2.25rem !important;
  }

  .text-bs-11 {
    font-family: 'BloggerSans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.6875rem !important;
  }

  #stickyArea p {
    font-family: 'BloggerSans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.5rem !important;
    text-transform: initial !important;
  }

  #stickyArea p.text-cr-14 {
    font-family: 'MyriadPro', sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    text-transform: initial !important;
  }

  .een-slogan-wrapper {
    min-height: 122px;
    background: url('/templates/clean-bootstrapv2/img/een_footer_bg2.png') no-repeat;
    margin-left: -10px;
    margin-right: -10px;

    @media (max-width: 575.98px) {
      background-image: url('/templates/clean-bootstrapv2/img/een_footer_bg_mobile.png');
    }

    @media (min-width: 992px) {
      background-size: 100% 100%;
    }
  }

  a {
    .text-black {
      color: #000000 !important;
    }
  }

  //.bg-form-wide.bg-lightGrey {
  //    background-image: none;
  //    background-color: white !important;
  //}

  .btn {
    &.btn-outline-primary {
      &:hover {
        color: #fff !important;
      }
    }
  }

  .social-link {
    &:hover {
      border-color: #006ba6 !important;

      path {
        fill: #006ba6 !important;
      }
    }
  }

  .eventBox {
    &:hover {
      .btn-module-event {
        background-color: #006ba6;
        color: #fff !important;
      }
    }
  }

}

.video-carousel .video-card {
  &:hover {
    background-color: #006ba6 !important;
    color: #fff !important;
    .video-description {
      background-color: #006ba6 !important;
      color: #fff !important;
    }
    p {
      color: #fff !important;

      svg path {
        fill: #fff !important;
      }
    }
  }

}
